.App {
  text-align: center;
}

.heroImage {
  background-image: url(../public/background.png);
  background-size: cover;
  opacity: 0.7;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.contactImage {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  object-fit: cover;
  height: 340px;
}

.panoImage {
  width: 100%;
  transition: 100ms ease-in-out;
  overflow: hidden;
}

.frontOfShop,
.mapOfShop {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  object-fit: cover;
  height: 420px;
  image-rendering: -webkit-optimize-contrast;
}

.shopOfShop {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  object-fit: scale-down;
  height: 480px;
  image-rendering: -webkit-optimize-contrast;
}

a {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 768px) {
  .heroImage {
    background-size: contain;
    opacity: 0.8;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top;
    height: 35vh;
    background-color: "#1e1e1e";
  }
}
